import { useEffect, useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';

import Loading from '../components/Loading';
import RegisterForm from '../components/forms/RegisterForm';
import { getInstitute } from '../helper/database_functions';
import InstitueRegistration from '../components/forms/InstituteRegistration';

function Register() {
  const [loading, setLoading] = useState(true);
  const [institute, setInstitute] = useState(null);

  const [params] = useSearchParams();

  useEffect(() => {
    getInstitute()
      .then(setInstitute)
      .then(() => setLoading(false))
      .catch(console.error);
  }, []);

  if (loading) return <Loading />;
  else {
    return (
      <Container width='100%' size='4'>
        {institute ? (
          <InstitueRegistration institute={institute} />
        ) : (
          <Flex
            direction='column'
            align='center'
            gap={{ initial: '4', md: '6' }}
          >
            <h1 className='text-2xl md:text-3xl font-bold text-center'>
              REGISTER
            </h1>

            <Flex
              direction='column'
              align='center'
              gap={{ initial: '4', md: '5' }}
              className='text-base md:text-lg font-semibold text-center'
            >
              <p>
                BSF is the largest inter-university and now, inter-school sports
                festival hosted by BITS Pilani, Dubai Campus. This year, it will
                witness universities from all over the UAE competing fiercely
                and showcasing their skills in a range of thrilling sports
                events like basketball, football, cricket, volleyball,
                badminton, and athletics. The festival promises intense matches,
                team spirit, and unforgettable moments, bringing together
                students from across the region in the spirit of sportsmanship
                and competition!
              </p>
              <p>
                To enroll for this event, kindly complete the form below with
                accurate details. Be assured that your information is treated
                with utmost confidentiality and is solely utilized for
                event-related correspondence and verification purposes only.
                Upon successful registration, you will receive a confirmation
                email containing your unique QR pass for event entry.
              </p>
            </Flex>

            <RegisterForm type={params.get('type')} />
          </Flex>
        )}
      </Container>
    );
  }
}

export default Register;
