import { Container, Flex, Grid, Tabs } from '@radix-ui/themes';
import React, { Suspense, useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

import Day from '../components/Day';
import Loading from '../components/Loading';
import { firestore } from '../services/firebase.config';
import ReactMarkdown from 'react-markdown';

function Events() {
  const [universityEvents, setUniversityEvents] = useState([]);
  const [schoolEvents, setSchoolEvents] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchLiveEvents = () => {
      try {
        const liveEventsCollection = collection(
          firestore,
          `/liveevents/${currentYear}/bsf`
        );

        const unsubscribeLiveEvents = onSnapshot(
          query(liveEventsCollection, orderBy('_createdBy.timestamp', 'asc')),
          async (snapshot) => {
            const updatedLiveEvents = await Promise.all(
              snapshot.docs.map(async (doc) => {
                const data = doc.data();
                return data;
              })
            );
            setLiveEvents(updatedLiveEvents);
            console.log(updatedLiveEvents);
          }
        );
        return () => {
          unsubscribeLiveEvents();
        };
      } catch (error) {
        console.error('Error fetching live events : ', error);
      }
    };
    fetchLiveEvents();
  }, [currentYear]);

  return (
    <Container size='4' align='center'>
      <Flex direction='column' gap={{ initial: '4', md: '6' }} align='center'>
        <h1 className='text-2xl md:text-3xl font-bold text-center'>
          Live Updates
        </h1>
        <Grid
          columns={{ initial: '1', md: '2' }}
          gap={{ initial: '4', md: '6' }}
        >
          {liveEvents.map((event, index) => {
            return (
              <div key={index}>
                <ReactMarkdown
                  className='flex flex-col gap-2 md:gap-4 text-center hover:scale-105 transition-all duration-500 cursor-pointer bg-gray-100 p-4 rounded-xl shadow-md '
                  components={{
                    a: ({ node, ...props }) => (
                      <a
                        rel='noopener noreferrer'
                        target='_blank'
                        className='text-primary'
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <h2
                        className='text-xl md:text-2xl font-bold text-center'
                        {...props}
                      />
                    ),
                  }}
                >
                  {event.update}
                </ReactMarkdown>
              </div>
            );
          })}
        </Grid>
      </Flex>
    </Container>
  );

  // return (
  //   <Container size='4' align='center'>
  //     <Tabs.Root
  //       defaultValue='dashboard'
  //       className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'
  //     >
  //       <Tabs.List className='text-lg md:text-xl font-bold'>
  //         <Tabs.Trigger value='dashboard'>UNIVERSITY</Tabs.Trigger>
  //         <Tabs.Trigger value='scanner'>SCHOOL</Tabs.Trigger>
  //       </Tabs.List>

  //       <Tabs.Content value='dashboard' className='w-full h-full'>
  //         <Flex
  //           direction='column'
  //           align='center'
  //           gap={{ initial: '4', md: '6' }}
  //           className='text-center'
  //         >
  //           <h1 >
  //             COMING SOON
  //           </h1>
  //           {/* <Suspense fallback={<Loading />}>
  //             {universityEvents && universityEvents.length !== 0 && (
  //               <Day
  //                 date='24TH APRIL, 2024'
  //                 events={universityEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 24 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense>
  //           <Suspense fallback={<Loading />}>
  //             {universityEvents && universityEvents.length !== 0 && (
  //               <Day
  //                 date='25TH APRIL, 2024'
  //                 events={universityEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 25 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense>
  //           <Suspense fallback={<Loading />}>
  //             {universityEvents && universityEvents.length !== 0 && (
  //               <Day
  //                 date='26TH APRIL, 2024'
  //                 events={universityEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 26 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense>
  //           <Suspense fallback={<Loading />}>
  //             {universityEvents && universityEvents.length !== 0 && (
  //               <Day
  //                 date='27TH APRIL, 2024'
  //                 events={universityEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 27 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense> */}
  //         </Flex>
  //       </Tabs.Content>

  //       <Tabs.Content value='scanner' className='w-full h-full'>
  //         <Flex
  //           direction='column'
  //           align='center'
  //           gap={{ initial: '4', md: '6' }}
  //           className='text-center'
  //         >
  //           <h1 className='text-2xl md:text-3xl font-bold text-center'>
  //             COMING SOON
  //           </h1>
  //           {/* <Suspense fallback={<Loading />}>
  //             {schoolEvents && schoolEvents.length !== 0 && (
  //               <Day
  //                 date='24TH APRIL, 2024'
  //                 events={schoolEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 24 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense>
  //           <Suspense fallback={<Loading />}>
  //             {schoolEvents && schoolEvents.length !== 0 && (
  //               <Day
  //                 date='25TH APRIL, 2024'
  //                 events={schoolEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 25 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense>
  //           <Suspense fallback={<Loading />}>
  //             {schoolEvents && schoolEvents.length !== 0 && (
  //               <Day
  //                 date='26TH APRIL, 2024'
  //                 events={schoolEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 26 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense>
  //           <Suspense fallback={<Loading />}>
  //             {schoolEvents && schoolEvents.length !== 0 && (
  //               <Day
  //                 date='27TH APRIL, 2024'
  //                 events={schoolEvents.filter((event) => {
  //                   const eventDate = new Date(event.dateAndTime * 1000);
  //                   return (
  //                     eventDate.getDate() === 27 && eventDate.getMonth() === 3
  //                   );
  //                 })}
  //               />
  //             )}
  //           </Suspense> */}
  //         </Flex>
  //       </Tabs.Content>
  //     </Tabs.Root>
  //   </Container>
  // );
}

export default Events;
