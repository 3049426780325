import { toast } from 'sonner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Flex, Spinner } from '@radix-ui/themes';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';

import {
  schoolBoys,
  schoolGirls,
  universityBoys,
  universityGirls,
} from '../data/sports';
import {
  generateQrCodeAsDataUrl,
  getInstitute,
  updateSportInDocument,
} from '../helper/database_functions';
import Button from '../components/Button';
import Loading from '../components/Loading';
import { parseData } from '../helper/helper_functions';
import PlayerForm from '../components/forms/PlayerForm';
import CaptainForm from '../components/forms/CaptainForm';
import { storageInstance } from '../services/firebase.config';
import TermsConditions from '../components/forms/TermsConditions';

const SportRegistration = () => {
  const navigate = useNavigate();
  const { sport } = useParams();
  const [parsedData, setParsedData] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [institute, setInstitute] = useState(null);
  const [sportsData, setSportsData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [instituteLoading, setInstituteLoading] = useState(true);

  // Closing regitraion for all except unis cityuni and londonamerican
  useEffect(() => {
    if (
      sport !== 'lawn-tennis-boys' &&
      sport !== 'lawn-tennis-girls' &&
      institute?.email !== 'cityuni@bsf.com' &&
      institute?.email !== 'londonamerican@bsf.com' &&
      institute?.institutionType === 'university' &&
      !institute?.sports?.[sport]
    ) {
      navigate('/register', { replace: true });
    }
  }, [institute, sport]);

  useEffect(() => {
    getInstitute()
      .then((result) => {
        setInstitute(result);
        if (!result) {
          window.location.href = '/register?type=institute';
        }
        getSportsData(result);
        setInstituteLoading(false);
      })
      .catch(console.error)
      .finally(() => {
        setInstituteLoading(false);
      });
  }, []);

  const getSportsData = (result) => {
    const data = result?.sports?.[sport];

    setSportsData({
      captain: data?.find((player) => player.playerType === 'captain') || {},
      mainPlayers: data?.filter((player) => player.playerType === 'main') || [],
      substitutePlayers:
        data?.filter((player) => player.playerType === 'substitute') || [],
    });
  };

  const sportGender = sport.split('-').at(-1);

  const currentSport = (
    institute?.institutionType === 'university'
      ? sportGender === 'boys'
        ? universityBoys
        : universityGirls
      : sportGender === 'boys'
      ? schoolBoys
      : schoolGirls
  ).find((item) => item.route === sport);

  const submitData = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage('');

    const formData = new FormData(e.target);
    var data = Object.fromEntries(formData.entries());

    const pData = parseData(data, currentSport);

    if (typeof pData[0] === 'string') {
      setErrorMessage(pData);
      setLoading(false);
      return;
    }

    setParsedData(pData);
    setOpen(true);

    setLoading(false);
  };

  const submitForm = async (sign) => {
    setLoading(true);

    try {
      const hashedId = institute?.institute;
      const qrCodeBase64 = generateQrCodeAsDataUrl(hashedId);
      const qrCodeRef = ref(
        storageInstance,
        `qr_codes/bsf-teams/${institute?.institute}.png`
      );
      const signRef = ref(
        storageInstance,
        `signatures/bsf-teams/${institute.institute}-${sport}.png`
      );
      await uploadString(qrCodeRef, qrCodeBase64, 'data_url');
      await uploadString(signRef, sign, 'data_url');
      const qrDownloadUrl = await getDownloadURL(qrCodeRef);
      const signDownloadUrl = await getDownloadURL(signRef);

      const finalData = {
        imageUrl: qrDownloadUrl,
        signUrl: signDownloadUrl,
        [sport]: [
          parsedData.captain,
          ...parsedData.mainPlayers,
          ...parsedData.substitutePlayers,
        ],
      };

      await updateSportInDocument(sport, finalData);

      navigate('/register', { replace: true });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error);
      setErrorMessage('Registration failed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  if (instituteLoading) return <Loading />;
  else {
    return (
      <Container width='100%' size='4'>
        <form onSubmit={submitData}>
          <Flex
            width='100%'
            align='center'
            justify='center'
            direction='column'
            gap={{ initial: '4', md: '6' }}
          >
            <h1 className='text-2xl md:text-3xl font-bold text-center'>
              {currentSport.name}
            </h1>

            <CaptainForm
              errorMessage={errorMessage}
              previousData={sportsData?.captain}
            />

            {[...Array(currentSport.mainPlayers - 1)].map((_, index) => (
              <PlayerForm
                key={index}
                index={index + 2}
                isSubstitute={false}
                errorMessage={errorMessage}
                previousData={sportsData?.mainPlayers[index]}
              />
            ))}

            {[...Array(currentSport.substitutePlayers)].map((_, index) => (
              <PlayerForm
                key={index}
                index={index + 1}
                isSubstitute={true}
                errorMessage={errorMessage}
                previousData={sportsData?.substitutePlayers[index]}
              />
            ))}

            <Button
              type='submit'
              title={loading ? <Spinner size='3' /> : 'SUBMIT'}
              className='px-8 md:px-16 w-full md:w-fit'
            />

            <TermsConditions
              open={open}
              setOpen={setOpen}
              submitForm={submitForm}
              institute={institute.institute}
            />
          </Flex>
        </form>
      </Container>
    );
  }
};

export default SportRegistration;
