import { Container, Flex } from '@radix-ui/themes';

const Sponsors = () => {
  return (
    <Container size='4' align='center'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '7', md: '9' }}
        className='text-center'
      >
        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            SPONSORS
          </h1>

          <p className='text-base md:text-lg font-semibold text-center'>
            On behalf of BITS Sports Festival 2024, we extend our deepest
            gratitude for your invaluable support and contribution to the
            success of our event.
            <br />
            As a sponsor, you have not only demonstrated your commitment to
            encouraging athletic excellence and fostering a spirit of teamwork
            but have also played a key role in creating unforgettable
            experiences for our community. We truly appreciate your partnership
            and look forward to achieving new heights together. Thank you once
            again for your unwavering dedication and generosity.
          </p>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            PREVIOUS SPONSORS
          </h1>

          <Flex
            width='100%'
            direction='row'
            align='center'
            className='justify-evenly'
          >
            <Flex
              width='100%'
              direction='column'
              align='center'
              gap={{ initial: '3', md: '5' }}
            >
              <img
                src='/assets/sponsors/desco.png'
                alt='DESCO'
                className='h-[50px] md:h-[80px]'
              />

              <h1 className='text-xl md:text-2xl font-bold text-center'>
                PRINT
              </h1>
            </Flex>

            <Flex
              width='100%'
              direction='column'
              align='center'
              gap={{ initial: '3', md: '5' }}
            >
              <img
                src='/assets/sponsors/radiomirchi.png'
                alt='Radio Mirchi'
                className='h-[50px] md:h-[80px]'
              />

              <h1 className='text-xl md:text-2xl font-bold text-center'>
                RADIO
              </h1>
            </Flex>

            <Flex
              width='100%'
              direction='column'
              align='center'
              gap={{ initial: '3', md: '5' }}
            >
              <img
                src='/assets/sponsors/zee.png'
                alt='ZEE'
                className='h-[50px] md:h-[80px]'
              />

              <h1 className='text-xl md:text-2xl font-bold text-center'>
                TELEVISION
              </h1>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <Flex
            width='100%'
            direction='row'
            align='center'
            className='justify-evenly flex-wrap'
            gap={{ initial: '5', md: '8' }}
          >
            <img
              src='/assets/sponsors/cocacola.png'
              alt='Coca Cola'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/skechers.png'
              alt='Skechers'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/bydi.png'
              alt='Bin Yaber'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/nikon.png'
              alt='Nikon'
              className='h-[50px] md:h-[80px]'
            />
          </Flex>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <Flex
            width='100%'
            direction='row'
            align='center'
            gap={{ initial: '5', md: '8' }}
            className='justify-evenly flex-wrap'
          >
            <img
              src='/assets/sponsors/dabur.png'
              alt='Dabur Vatika'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/chachachai.jpg'
              alt='Cha Cha Chai'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/caramalise.png'
              alt='Caramalise'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/chocofondue.png'
              alt='Choco Fondue'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/wedesi.png'
              alt='We Desi'
              className='h-[50px] md:h-[80px]'
            />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Sponsors;
