import React, { useState } from 'react';
import { Container, Flex, Select } from '@radix-ui/themes';

import LoginForm from './LoginForm';
import VisitorRegistration from './VisitorRegistration';

const categories = {
  visitor: <VisitorRegistration />,
  institute: <LoginForm />,
};

const RegisterForm = ({ type }) => {
  const [category, setCategory] = useState(type ?? 'visitor');

  return (
    <Container
      p={{
        initial: '6',
        md: '9',
      }}
      className='bg-grayColor rounded-[50px] w-full'
    >
      <Flex
        width='100%'
        direction='column'
        align='center'
        justify='between'
        gap={{ initial: '4', md: '6' }}
      >
        <Flex
          direction='row'
          align='center'
          gap='4'
          width='100%'
          justify='center'
        >
          <p className='font-semibold'>Category</p>

          <Select.Root
            size='3'
            defaultValue='visitor'
            value={category}
            onValueChange={(value) => {
              setCategory(value);
            }}
          >
            <Select.Trigger className='w-2/3 md:w-1/3 font-semibold' />
            <Select.Content>
              <Select.Group>
                <Select.Item value='visitor'>Visitor</Select.Item>
                <Select.Item value='institute'>School / University</Select.Item>
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </Flex>

        {categories[category]}
      </Flex>
    </Container>
  );
};

export default RegisterForm;
