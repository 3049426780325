const Loading = () => {
  return (
    <div className='absolute top-0 left-0 right-0 h-dvh w-screen flex items-center justify-center z-50 bg-whiteColor'>
      <img
        src='/assets/logos/BITSLogoVertical.png'
        alt='loading'
        width={100}
        height={100}
        className='w-[70px] md:w-[100px] h-auto'
      />
    </div>
  );
};

export default Loading;
