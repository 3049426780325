import { useLocation } from 'react-router-dom';
import { Container, Flex } from '@radix-ui/themes';
import { footer } from '../data/footer';

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <Container
      width='100%'
      className={`${
        pathname === '/'
          ? 'bg-whiteColor text-blackColor'
          : 'bg-blackColor text-whiteColor'
      } rounded-tl-[20px] rounded-tr-[20px] max-h-[100px] md:max-h-[200px] grid place-items-center bottom-0`}
      p={{ initial: '6', md: '9' }}
    >
      <Flex
        direction={{ initial: 'column', md: 'row' }}
        align='center'
        justify='between'
        width='100%'
        gap={{
          initial: '2',
          md: '4',
        }}
      >
        <Flex
          direction={{
            initial: 'row',
            md: 'column',
          }}
          align='center'
          justify={{
            initial: 'between',
            md: 'center',
          }}
          gap={{
            initial: '2',
            md: '4',
          }}
        >
          <h1 className='text-md md:text-3xl font-bold'>@bsf.bits</h1>

          <Flex direction='row' align='center' gap='1'>
            {footer.map((social, index) => (
              <a
                href={social.link}
                target='_blank'
                rel='noreferrer'
                key={index}
              >
                <img
                  src={social.image}
                  alt={social.alt}
                  className={`w-[25px] md:w-[40px] ${
                    pathname === '/' ? '' : 'filter invert'
                  }`}
                />
              </a>
            ))}
          </Flex>
        </Flex>

        <h1 className='text-md md:text-3xl font-bold text-center'>
          bits-dubai.ac.ae | bitsdubaievents.com
        </h1>
      </Flex>
    </Container>
  );
};

export default Footer;
