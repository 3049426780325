const Button = ({ type, title, onClick, className, ...props }) => {
  return (
    <button
      {...props}
      type={type}
      onClick={onClick}
      className={`${className} px-5 py-2 bg-blackColor hover:bg-grayColor text-whiteColor text-center font-bold rounded-xl text-sm md:text-md cursor-pointer transition-all duration-200 ease-in disabled:opacity-50 disabled:hover:bg-blackColor disabled:hover:text-whiteColor`}
    >
      {title}
    </button>
  );
};

export default Button;
