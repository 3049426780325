const Input = ({
  type = 'text',
  name,
  value,
  onChange,
  accept,
  placeholder,
  required,
  defaultValue,
  ...props
}) => {
  return (
    <input
      type={type}
      accept={accept}
      name={name}
      value={value}
      required={required}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className='border-2 border-blackColor rounded-xl text-center py-3 md:py-3 px-2 md:px-10 font-bold text-blackColor text-sm w-full focus:outline-none focus:border-blackColor transition-all duration-300 ease-in-out placeholder:text-blackColor'
      {...props}
    />
  );
};

export default Input;

{
  /* <Input
  className={
    field.type === 'file'
      ? 'flex w-full border border-input text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium'
      : 'w-full text-primaryColor'
  }
  type={'file'}
  accept={field.accept ? field.accept : undefined}
/>; */
}
