import { Container, Flex } from '@radix-ui/themes';
import Button from '../components/Button';

const Information = () => {
  return (
    <Container size='4' align='center'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '7', md: '9' }}
        className='text-center'
      >
        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            INFORMATION
          </h1>

          <p className='text-base md:text-lg font-semibold text-center'>
            The Sports department of BITS Pilani, Dubai Campus is proud to host
            the 21st edition of the BITS SPORTS FESTIVAL- the largest
            Inter-University and Inter-School Sports Festival in the United Arab
            Emirates, both in terms of sporting events and player participation.
            <br />
            <br />
            Established in 2003 by Dr. M. Rafiuddin, the BITS Sports Festival
            (BSF) has evolved from a modest event featuring four sports to a
            grand spectacle encompassing 11 disciplines. Over the years, it has
            garnered national acclaim and attracted international sports icons
            such as Mr. Diego Maradona, Mr. Pulella Gopichand, Ms. Sania Mirza,
            Mr. Sushil Kumar, Mr. Vijender Singh, Ms. Saina Nehwal, Mr. Madan
            Lal, Mrs. Koneru Humpy, Mr. Piyush Chavla, Mr DJ Bravo, Mr. Virender
            Sehwag, Mr. Mohammad Azharuddin, Mrs. Mary Kom and Universe Boss,
            Mr. Chris Gayle. This year, BSF 2024 will be held from 25th to 29th
            November, 2024.
            <br />
            <br />
            BSF attracts players from more than 35 universities and 25 schools
            all across U.A.E, and a floating crowd of over 4500, across 5 days.
            The event features teams participating in Cricket, Volleyball,
            Basketball, Tennis, Table Tennis, Badminton, Chess as well as
            Football for boys and Throwball for girls.
            <br />
            <br />
            The excitement of BSF is not just confined to the sports field;
            there are live events, mouth-watering food, background music, fun
            interviews and jolly games that keep the audience enthralled.
          </p>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            RULE BOOKS
          </h1>

          <Flex direction='row' align='center' gap='5'>
            <Button
              className='text-lg md:text-xl'
              onClick={() => {
                window.open('/assets/rulebooks/university.pdf', '_blank');
              }}
              title='UNIVERSITY'
            />
            <Button
              className='text-lg md:text-xl'
              onClick={() => {
                window.open('/assets/rulebooks/school.pdf', '_blank');
              }}
              title='SCHOOL'
            />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Information;
