import { RotateCw } from 'lucide-react';
import { useRef, useState } from 'react';
import { AlertDialog, Flex } from '@radix-ui/themes';
import SignatureCanvas from 'react-signature-canvas';

import Button from '../Button';

const TermsConditions = ({ institute, open, setOpen, submitForm }) => {
  const sigCanvas = useRef(null);
  const isMobile = window.screen.width <= 768;
  const [errorMessage, setErrorMessage] = useState('');

  const submitSign = async () => {
    if (sigCanvas.current.isEmpty()) {
      setErrorMessage('Please sign the terms and conditions');
      return;
    }

    const signatureDataURL = await sigCanvas.current.toDataURL();

    setOpen(false);

    submitForm(signatureDataURL);
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Content className='max-w-full md:max-w-4xl max-h-[70svh] md:max-h-svh flex flex-col items-center gap-5 p-10 md:p-12  overflow-scroll'>
        <AlertDialog.Title className='text-xl md:text-2xl text-center'>
          Terms and Conditions
        </AlertDialog.Title>

        <AlertDialog.Description className='flex flex-col items-center gap-5 text-sm md:text-base text-balance text-justify'>
          <p>
            As a representative of{' '}
            <span className='font-semibold'>{institute}</span>, I hereby pledge
            that all participating teams from our institution will engage in
            fair and respectful conduct throughout the duration of the 21st BITS
            Sports Festival 2024. We commit to upholding the integrity and
            spirit of the festival by refraining from:
          </p>

          <ol className='text-pretty'>
            <li>
              <span className='font-semibold'>Unsportsmanlike Conduct : </span>
              Any actions or strategies that undermine fair gameplay, including
              but not limited to cheating, dishonesty, or sabotage.
            </li>
            <li>
              <span className='font-semibold'>
                Verbal and Physical Misconduct :{' '}
              </span>
              Use of abusive, threatening, or offensive language, as well as any
              form of physical aggression, harassment, or assault.
            </li>
            <li>
              <span className='font-semibold'>Discriminatory Behavior : </span>
              Displaying or promoting racism, sexism, or any other form of
              discrimination against individuals or teams based on race, gender,
              nationality, religion, or other personal characteristics.
            </li>
            <li>
              <span className='font-semibold'>
                Anti-social and Disruptive Activities :{' '}
              </span>
              Engaging in activities that could disturb the peace, compromise
              safety, or negatively impact the festival environment for
              participants and spectators.
            </li>
          </ol>

          <p className='text-lg md:text-xl font-bold'>
            Consequences of Violations
          </p>

          <p>
            Any breach of the above terms will result in immediate
            disqualification of the respective university from all sports events
            in the festival, as well as potential exclusion from future editions
            of the festival.
            <br />
            This undertaking is mandatory. Only teams that agree to these terms
            and conditions will be permitted to participate in the BITS Sports
            Festival 2024.
          </p>
        </AlertDialog.Description>

        <SignatureCanvas
          ref={sigCanvas}
          penColor='black'
          clearOnResize={true}
          canvasProps={{
            height: isMobile ? 500 : 300,
            className:
              'sigCanvas border-black border-2 rounded-xl w-full md:w-2/3',
          }}
        />

        <div>
          <RotateCw
            className='cursor-pointer'
            onClick={() => sigCanvas.current.clear()}
          />
        </div>

        {errorMessage && (
          <p className='text-redColor text-sm md:text-md font-semibold'>
            {errorMessage}
          </p>
        )}

        <Flex direction='row' align='center' gap='4'>
          <AlertDialog.Cancel>
            <Button title='Cancel' onClick={() => setOpen(false)} />
          </AlertDialog.Cancel>

          <AlertDialog.Action>
            <Button title='Confirm' onClick={submitSign} />
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default TermsConditions;
