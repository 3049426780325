export const schoolBoys = [
  {
    name: 'Athletics Boys',
    route: 'athletics-boys',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Badminton Boys',
    route: 'badminton-boys',
    mainPlayers: 5,
    substitutePlayers: 2,
  },
  {
    name: 'Basketball Boys',
    route: 'basketball-boys',
    mainPlayers: 5,
    substitutePlayers: 7,
  },
  {
    name: 'Chess Boys',
    route: 'chess-boys',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Cricket Boys',
    route: 'cricket-boys',
    mainPlayers: 11,
    substitutePlayers: 4,
  },
  {
    name: 'Football Boys',
    route: 'football-boys',
    mainPlayers: 8,
    substitutePlayers: 4,
  },
  {
    name: 'Handball Boys',
    route: 'handball-boys',
    mainPlayers: 7,
    substitutePlayers: 3,
  },
  {
    name: 'Lawn Tennis Boys',
    route: 'lawn-tennis-boys',
    mainPlayers: 3,
    substitutePlayers: 2,
  },
  {
    name: 'Table Tennis Boys',
    route: 'table-tennis-boys',
    mainPlayers: 5,
    substitutePlayers: 0,
  },
  {
    name: 'Volleyball Boys',
    route: 'volleyball-boys',
    mainPlayers: 6,
    substitutePlayers: 6,
  },
];

export const schoolGirls = [
  {
    name: 'Athletics Girls',
    route: 'athletics-girls',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Badminton Girls',
    route: 'badminton-girls',
    mainPlayers: 5,
    substitutePlayers: 2,
  },
  {
    name: 'Basketball Girls',
    route: 'basketball-girls',
    mainPlayers: 5,
    substitutePlayers: 7,
  },
  {
    name: 'Chess Girls',
    route: 'chess-girls',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Cricket Girls',
    route: 'cricket-girls',
    mainPlayers: 11,
    substitutePlayers: 4,
  },
  {
    name: 'Lawn Tennis Girls',
    route: 'lawn-tennis-girls',
    mainPlayers: 2,
    substitutePlayers: 2,
  },
  {
    name: 'Table Tennis Girls',
    route: 'table-tennis-girls',
    mainPlayers: 5,
    substitutePlayers: 0,
  },
  {
    name: 'Throwball Girls',
    route: 'throwball-girls',
    mainPlayers: 9,
    substitutePlayers: 3,
  },
  {
    name: 'Volleyball Girls',
    route: 'volleyball-girls',
    mainPlayers: 6,
    substitutePlayers: 6,
  },
];

export const universityBoys = [
  {
    name: 'Athletics Boys',
    route: 'athletics-boys',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Badminton Boys',
    route: 'badminton-boys',
    mainPlayers: 5,
    substitutePlayers: 0,
  },
  {
    name: 'Basketball Boys',
    route: 'basketball-boys',
    mainPlayers: 5,
    substitutePlayers: 7,
  },
  {
    name: 'Boxing Boys',
    route: 'boxing-boys',
    mainPlayers: 2,
    substitutePlayers: 1,
  },
  {
    name: 'Chess Boys',
    route: 'chess-boys',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Cricket Boys',
    route: 'cricket-boys',
    mainPlayers: 11,
    substitutePlayers: 4,
  },
  {
    name: 'Football Boys',
    route: 'football-boys',
    mainPlayers: 8,
    substitutePlayers: 4,
  },
  {
    name: 'Lawn Tennis Boys',
    route: 'lawn-tennis-boys',
    mainPlayers: 3,
    substitutePlayers: 5,
  },
  {
    name: 'Table Tennis Boys',
    route: 'table-tennis-boys',
    mainPlayers: 5,
    substitutePlayers: 0,
  },
  {
    name: 'Volleyball Boys',
    route: 'volleyball-boys',
    mainPlayers: 6,
    substitutePlayers: 6,
  },
];

export const universityGirls = [
  {
    name: 'Athletics Girls',
    route: 'athletics-girls',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Badminton Girls',
    route: 'badminton-girls',
    mainPlayers: 5,
    substitutePlayers: 0,
  },
  {
    name: 'Basketball Girls',
    route: 'basketball-girls',
    mainPlayers: 5,
    substitutePlayers: 7,
  },
  {
    name: 'Boxing Girls',
    route: 'boxing-girls',
    mainPlayers: 2,
    substitutePlayers: 1,
  },
  {
    name: 'Chess Girls',
    route: 'chess-girls',
    mainPlayers: 4,
    substitutePlayers: 1,
  },
  {
    name: 'Cricket Girls',
    route: 'cricket-girls',
    mainPlayers: 11,
    substitutePlayers: 4,
  },
  {
    name: 'Lawn Tennis Girls',
    route: 'lawn-tennis-girls',
    mainPlayers: 3,
    substitutePlayers: 5,
  },
  {
    name: 'Table Tennis Girls',
    route: 'table-tennis-girls',
    mainPlayers: 5,
    substitutePlayers: 0,
  },
  {
    name: 'Throwball Girls',
    route: 'throwball-girls',
    mainPlayers: 9,
    substitutePlayers: 3,
  },
  {
    name: 'Volleyball Girls',
    route: 'volleyball-girls',
    mainPlayers: 6,
    substitutePlayers: 6,
  },
];
