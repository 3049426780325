import { Flex, Grid } from '@radix-ui/themes';

import Input from '../Input';
import { captainFormDetails } from '../../data/registerForm';

const CaptainForm = ({ previousData, errorMessage }) => {
  return (
    <Flex
      width='100%'
      align='center'
      justify='center'
      direction='column'
      gap={{ initial: '4', md: '6' }}
      p={{
        initial: '3',
        md: '6',
      }}
      className='bg-grayColor rounded-xl'
    >
      <h1 className='text-xl md:text-2xl font-semibold text-center'>Captain</h1>

      <Grid
        width='100%'
        align='center'
        gap={{ initial: '4', md: '6' }}
        columns={{ initial: '1', sm: '2' }}
      >
        {captainFormDetails.map((field, index) => (
          <Input
            key={index}
            name={'captain' + field.name}
            type={field.type}
            placeholder={field.label}
            required={true}
            defaultValue={previousData ? previousData[field.name] : ''}
          />
        ))}
      </Grid>

      {errorMessage[0] === 'captain' && (
        <p className='text-redColor text-sm md:text-md font-semibold'>
          {errorMessage[1]}
        </p>
      )}
    </Flex>
  );
};

export default CaptainForm;
