import CryptoJS from 'crypto-js';
import { sha256 } from 'crypto-hash';

import { Values } from './values';

export const setEncryptedItem = (key, value) => {
  try {
    const stringValue = JSON.stringify(value);
    const encryptedValue = CryptoJS.AES.encrypt(
      stringValue,
      Values.encryptionKey
    ).toString();
    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error('Error encrypting and storing data:', error);
  }
};

export const getDecryptedItem = (key) => {
  try {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) {
      return null;
    }
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedValue,
      Values.encryptionKey
    );
    const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
    const parsedValue = JSON.parse(decryptedValue);
    return parsedValue;
  } catch (error) {
    console.error('Error decrypting and retrieving data:', error);
    return null;
  }
};

export const generateQrCodeAsDataUrl = (hashedId) => {
  var QRCode = require('qrcode');
  var qrUrl = '';
  QRCode.toDataURL(hashedId, function (err, url) {
    qrUrl = url;
  });
  return qrUrl;
};

export const hashValue = async (value) => {
  const localHash = await sha256(value);
  return localHash;
};

export function hasMatch(input, pattern) {
  var regex = new RegExp(pattern);
  return regex.test(input);
}

export const getAge = (dob) => {
  var today = new Date();
  var birthDate = new Date(dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const correctEmiratesId = (emiratesId) => {
  var eid = emiratesId.replace(/-/g, '');
  return eid.replace(/\s+/g, '');
};

export const checkErrors = (emiratesId, phone, dateOfBirth) => {
  if (!Values.emiratesIdRegex.test(emiratesId)) {
    return 'Please enter a valid Emirates ID number';
  }

  if (phone && !Values.phoneRegex.test(phone)) {
    return 'Please enter a valid phone number';
  }

  if (dateOfBirth) {
    var today = new Date();
    var birthDate = new Date(dateOfBirth);
    if (birthDate >= today) {
      return 'Please enter a valid date of birth';
    }
  }

  return null;
};

export const parseData = (data, currentSport) => {
  const captain = {
    name: data.captainname,
    email: data.captainemail,
    emiratesId: correctEmiratesId(data.captainemiratesId),
    dateOfBirth: data.captaindateOfBirth,
    phone: data.captainphone,
    age: getAge(data.captaindateOfBirth),
    playerType: 'captain',
  };

  const error = checkErrors(
    captain.emiratesId,
    captain.phone,
    captain.dateOfBirth
  );
  if (error !== null) {
    return ['captain', error];
  }

  const mainPlayers = [];

  for (let i = 2; i <= currentSport.mainPlayers; i++) {
    const player = {
      name: data[`mainname${i}`],
      email: data[`mainemail${i}`],
      emiratesId: correctEmiratesId(data[`mainemiratesId${i}`]),
      dateOfBirth: data[`maindateOfBirth${i}`],
      age: getAge(data[`maindateOfBirth${i}`]),
      playerType: 'main',
    };

    const error = checkErrors(player.emiratesId, null, player.dateOfBirth);

    if (error !== null) {
      return [`main${i}`, error];
    }

    mainPlayers.push(player);
  }

  const substitutePlayers = [];

  for (let i = 1; i <= currentSport.substitutePlayers; i++) {
    if (
      !data[`substitutename${i}`] &&
      !data[`substituteemail${i}`] &&
      !data[`substituteemiratesId${i}`] &&
      !data[`substitutedateOfBirth${i}`]
    ) {
      continue;
    }

    if (
      (data[`substitutename${i}`] ||
        data[`substituteemail${i}`] ||
        data[`substituteemiratesId${i}`] ||
        data[`substitutedateOfBirth${i}`]) &&
      !(
        data[`substitutename${i}`] &&
        data[`substituteemail${i}`] &&
        data[`substituteemiratesId${i}`] &&
        data[`substitutedateOfBirth${i}`]
      )
    ) {
      return [`substitute${i}`, 'Please fill or remove all the fields'];
    }

    const player = {
      name: data[`substitutename${i}`],
      email: data[`substituteemail${i}`],
      emiratesId: correctEmiratesId(data[`substituteemiratesId${i}`]),
      dateOfBirth: data[`substitutedateOfBirth${i}`],
      age: getAge(data[`substitutedateOfBirth${i}`]),
      playerType: 'substitute',
    };

    const error = checkErrors(player.emiratesId, null, player.dateOfBirth);
    if (error !== null) {
      return [`substitute${i}`, error];
    }

    substitutePlayers.push(player);
  }

  return {
    captain,
    mainPlayers,
    substitutePlayers,
  };
};
