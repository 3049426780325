import React, { Suspense } from 'react';
import { logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';

import NavBar from './NavBar';
import Footer from './Footer';
import Loading from './Loading';
import { Toaster } from './Toaster';
import MobileNavBar from './MobileNavBar';
import { analytics } from '../services/firebase.config';

function Layout({ children }) {
  logEvent(analytics, 'website_start');
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <main
        className={`min-h-dvh w-dvw flex flex-col justify-between gap-5 md:gap-10 ${
          pathname === '/'
            ? 'bg-blackColor text-whiteColor'
            : 'bg-whiteColor text-blackColor'
        } `}
      >
        <img
          alt='logo'
          src='/assets/logos/bsf-logo.png'
          className='absolute z-10 left-3 md:left-5 top-3 md:top-3 h-auto w-[50px] md:w-[80px]'
        />

        <img
          alt='logo'
          src='/assets/logos/BITSLogo.png'
          className='absolute z-10 right-3 md:right-5 top-3 md:top-8 h-auto w-[120px] md:w-[150px] hidden md:block'
        />

        <div className='absolute z-10 top-7 w-full flex-row items-center justify-center hidden md:flex'>
          <NavBar />
        </div>

        <div className='absolute z-10 top-5 right-5 block md:hidden'>
          <MobileNavBar />
        </div>

        <div className='pt-20 md:pt-32 px-5 md:px-0'>
          <Suspense fallback={<Loading />}>{children}</Suspense>

          <Toaster />
        </div>

        <Footer />
      </main>
    </React.Fragment>
  );
}

export default Layout;
