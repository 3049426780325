export const captainFormDetails = [
  {
    name: 'name',
    label: 'Full Name',
    type: 'text',
  },
  {
    name: 'emiratesId',
    label: 'Emirates ID',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    name: 'phone',
    label: 'Phone Number',
    type: 'tel',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    type: 'date',
  },
];

export const playerFormDetails = [
  {
    name: 'name',
    label: 'Full Name',
    type: 'text',
  },
  {
    name: 'emiratesId',
    label: 'Emirates ID',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    type: 'date',
  },
];

export const visitorFormDetails = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'emiratesId',
    label: 'Emirates ID',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    name: 'phone',
    label: 'Phone Number',
    type: 'tel',
  },
];

export const loginFormDetails = [
  {
    name: 'email',
    label: 'Username',
    type: 'email',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
  },
];
