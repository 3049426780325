import { Menu } from 'lucide-react';
import { Flex } from '@radix-ui/themes';
import React, { useState } from 'react';
import { Box, Drawer, ModalClose } from '@mui/joy';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../data/navBar';

const MobileNavBar = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Menu
        color={pathname === '/' ? '#FDFDFD' : '#0D0D0D'}
        onClick={() => setOpen(true)}
      />
      <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
        <Box
          className={`h-dvh ${
            pathname === '/' ? 'bg-blackColor' : 'bg-whiteColor'
          }`}
        >
          <Box className='w-full flex flex-row items-center justify-end p-4'>
            <ModalClose sx={{ position: 'initial' }} />
          </Box>

          <Flex
            direction='column'
            align='center'
            className='text-xl font-semibold p-4 gap-2'
          >
            {routes.map((r, index) => (
              <Link
                key={index}
                to={r.route}
                onClick={() => setOpen(false)}
                className={`py-4 w-full rounded-xl text-center ${
                  pathname === '/'
                    ? 'bg-whiteColor text-blackColor'
                    : 'bg-blackColor text-whiteColor'
                }`}
              >
                {r.name}
              </Link>
            ))}
          </Flex>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default MobileNavBar;
