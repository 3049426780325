export const footer = [
  {
    link: 'https://twitter.com/bitspilanidubai',
    image: '/assets/socials/x.png',
    alt: 'X',
  },
  {
    link: 'https://www.instagram.com/bitssportsfest/',
    image: '/assets/socials/instagram.png',
    alt: 'Instagram',
  },
  {
    link: 'https://www.youtube.com/channel/UClKXaO8gB10OObAkoEkyNDQ',
    image: '/assets/socials/youtube.png',
    alt: 'Youtube',
  },
  {
    link: 'https://www.linkedin.com/school/birla-institute-of-technology-and-science-pilani-dubai/',
    image: '/assets/socials/linkedin.png',
    alt: 'LinkedIn',
  },
];
