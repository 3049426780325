import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Gallery from './pages/Gallery';
import Events from './pages/Events';
import Register from './pages/Register';
import Sponsors from './pages/Sponsors';
import Layout from './components/Layout';
import Loading from './components/Loading';
import Information from './pages/Information';
import SportRegistration from './pages/SportRegistration';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Home />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/information'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Information />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/events'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Events />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/register'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Register />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/register/:sport'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <SportRegistration />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/sponsors'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Sponsors />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/gallery'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Gallery />
                </Layout>
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
