export const routes = [
  {
    name: 'Home',
    route: '/',
  },
  {
    name: 'Information',
    route: '/information',
  },
  {
    name: 'Live',
    route: '/events',
  },
  {
    name: 'Register',
    route: '/register',
  },
  {
    name: 'Sponsors',
    route: '/sponsors',
  },
  {
    name: 'Gallery',
    route: '/gallery',
  },
];
