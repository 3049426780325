import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { firestore, storageInstance } from '../services/firebase.config';
import { getDecryptedItem, setEncryptedItem } from './helper_functions';
import { toast } from 'sonner';
import emailjs from 'emailjs-com';
import { getDownloadURL, listAll, ref } from 'firebase/storage';

export const checkDuplicateEntry = async (emiratesId) => {
  const currentYear = new Date().getFullYear();
  const q = query(
    collection(
      firestore,
      `registrations/${currentYear}/bsf/visitors/registrations`
    ),
    where('emiratesId', '==', emiratesId)
  );

  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    return false;
  } else {
    return true;
  }
};

export const login = async (email, password) => {
  const currentYear = new Date().getFullYear();
  const q = query(
    collection(
      firestore,
      `registrations/${currentYear}/bsf/institutes/registrations`
    ),
    where('email', '==', email),
    where('password', '==', password)
  );

  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    return false;
  } else {
    return snapshot.docs[0].data();
  }
};

export const getInstitute = async () => {
  const currentYear = new Date().getFullYear();
  const currentInstitute = getDecryptedItem('institute');
  if (!currentInstitute) return false;
  const q = query(
    collection(
      firestore,
      `registrations/${currentYear}/bsf/institutes/registrations`
    ),
    where('email', '==', currentInstitute.email)
  );

  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    return false;
  } else {
    const institute = snapshot.docs[0].data();
    setEncryptedItem('institute', institute);
    return institute;
  }
};

export const generateQrCodeAsDataUrl = (email) => {
  var QRCode = require('qrcode');
  var qrUrl = '';
  QRCode.toDataURL(email, function (err, url) {
    qrUrl = url;
  });
  return qrUrl;
};

export const updateSportInDocument = async (sport, data) => {
  const currentYear = new Date().getFullYear();
  const currentInstitute = getDecryptedItem('institute');
  if (!currentInstitute) throw new Error('Institute not found');
  const institutesRef = collection(
    firestore,
    `registrations/${currentYear}/bsf/institutes/registrations`
  );
  const q = query(institutesRef, where('email', '==', currentInstitute.email));

  try {
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      throw new Error('Institute not found');
    }

    querySnapshot.forEach(async (docSnapshot) => {
      const docRef = doc(
        firestore,
        `registrations/${currentYear}/bsf/institutes/registrations`,
        docSnapshot.id
      );

      await updateDoc(docRef, {
        imageUrl: data.imageUrl,
        [`${sport}-sign-url`]: data.signUrl,
        [`sports.${sport}`]: data[sport],
      });

      console.log('Document successfully updated!');
      toast.success('Registration updated succesfully');

      await sendTeamEmail(data[sport], data.imageUrl, sport);
      toast.success('Email sent succesfully');

      //   TODO enable this line
      //   window.location.href = '/register';
    });
  } catch (error) {
    toast.error(error?.message);
    console.error('Error updating document:', error);
  }
};

export const sendTeamEmail = async (sportData, qrUrl, sport) => {
  const email = sportData.find(
    (player) => player.playerType === 'captain'
  ).email;
  const playerRows = sportData
    .map((player) => {
      return `
      <tr>
        <td style="font-style: italic;">${
          player.playerType.charAt(0).toUpperCase() + player.playerType.slice(1)
        }</td>
        <td>${player.name}</td>
        <td>${player.emiratesId}</td>
        <td>${player.age}</td>
      </tr>
    `;
    })
    .join('');

  const playerTable = `
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="text-align: left; border: 1px solid #ddd; padding: 8px;">Player Type</th>
          <th style="text-align: left; border: 1px solid #ddd; padding: 8px;">Name</th>
          <th style="text-align: left; border: 1px solid #ddd; padding: 8px;">Emirates ID</th>
          <th style="text-align: right; border: 1px solid #ddd; padding: 8px;">Age</th>
        </tr>
      </thead>
      <tbody>
        ${playerRows}
      </tbody>
    </table>
  `;

  const templateParams = {
    email: email,
    sport: sport.split('-').join(' ')?.toString().toUpperCase(),
    playerTable: playerTable,
    imageUrl: qrUrl,
  };

  await emailjs.send(
    'service_t8clwjo',
    'template_5dqi3of',
    templateParams,
    'V2Yz1dwEESLY22zXz'
  );
};

export const getImages = async (path) => {
  const folderRef = ref(storageInstance, path);

  try {
    const result = await listAll(folderRef);

    const urlPromises = result.items.map(async (itemRef) => {
      const url = await getDownloadURL(itemRef);
      return { name: itemRef.name, url };
    });

    const urlObjects = await Promise.all(urlPromises);
    urlObjects.sort((a, b) => a.name.localeCompare(b.name));

    return urlObjects.map((obj) => obj.url);
  } catch (error) {
    console.error('Error getting images:', error);
    return [];
  }
};
