import React from 'react';
import { Container, Flex, Grid } from '@radix-ui/themes';
import { Link } from 'react-router-dom';

const info = [
  {
    title: 'Experience',
    description:
      'Experience the thrill of BSF and immerse yourself in exciting activities, from challenging competitions to unforgettable events that will test your skills and endurance.',
    content: (
      <Link to={'/register?type=visitor'}>
        <img
          src='/assets/landing/experience.png'
          alt='Experience'
          className='rounded-[20px] aspect-video w-full h-auto'
        />
      </Link>
    ),
  },
  {
    title: 'Participate',
    description:
      'Participate for the BSF, and go head to head against top tier players in a variety of sports.',

    content: (
      <Link to={'/register?type=institute'}>
        <img
          src='/assets/landing/participate.png'
          alt='Participate'
          className='rounded-[20px] aspect-video w-full h-auto'
        />
      </Link>
    ),
  },

  {
    title: 'Visit',
    description:
      'Visit BSF with ease! Follow our simple directions to reach us and dive into an atmosphere of sportsmanship and adventure.',
    content: (
      <div className='w-full aspect-video bg-grayColor rounded-[20px]'>
        <iframe
          title='BPDC Map'
          style={{ border: '0', borderRadius: '20px' }}
          loading='eager'
          allowFullScreen
          src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJg3YLr-xjXz4Rxzv2jtsDiRc&key=AIzaSyAeQn41vC5LzF_Wote1l6zB4DiCuvjrZ9s'
          className='h-full w-full rounded-3xl'
        ></iframe>
      </div>
    ),
  },
];

const Home = () => {
  return (
    <React.Fragment>
      <Flex
        align='center'
        className='h-dvh -mt-20 md:-mt-32 -ml-5 md:-ml-0 -mr-5 md:-mr-0'
      >
        <img
          alt='bg'
          loading='eager'
          src='/assets/landing/bg.jpg'
          className='object-cover h-dvh w-full brightness-50'
        />

        <Flex
          direction='column'
          justify='center'
          align='center'
          className='absolute w-full h-dvh'
        >
          <h1 className='text-5xl md:text-9xl font-bold text-center'>
            RUN.PLAY.<span className='text-[#D3214D]'>WIN</span>
          </h1>

          <h2 className='text-lg md:text-3xl font-bold text-center'>
            BITS Sports Festival - BSF 2024
          </h2>
        </Flex>
      </Flex>

      <Container size='4' align='center' className='mt-5 md:mt-10'>
        <Grid
          align='center'
          gap={{ initial: '6', md: '8' }}
          columns={{ initial: '1', md: '3' }}
        >
          {info.map(({ title, description, content }, index) => (
            <Flex
              key={index}
              direction='column'
              align='center'
              className='w-full h-full'
              gap={{
                initial: '2',
                md: '4',
              }}
            >
              {content}

              <h1 className='font-bold text-4xl md:text-5xl'>{title}</h1>

              <p className='text-sm md:text-md font-bold text-center'>
                {description}
              </p>
            </Flex>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Home;
