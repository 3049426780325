import { useEffect, useState } from 'react';

import Loading from '../components/Loading';
import { getImages } from '../helper/database_functions';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const imagesData = await getImages('/files/2024/bsf');

        console.log(imagesData);

        setImages(imagesData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className='grid gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
        {images.map((image, index) => (
          <div
            key={index}
            className='overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300'
          >
            <img
              src={image}
              alt={`BSF Image ${index}`}
              className='w-full h-full object-cover'
            />
          </div>
        ))}
      </div>
    );
  }
};

export default Gallery;
