import React from 'react';
import { Flex } from '@radix-ui/themes';
import { Link, useLocation } from 'react-router-dom';

import { routes } from '../data/navBar';

const NavBar = () => {
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <Flex
        direction='row'
        align='center'
        justify='between'
        gap={{ initial: '5', md: '9' }}
        className={`${
          pathname === '/'
            ? 'bg-whiteColor text-blackColor'
            : 'bg-blackColor text-whiteColor'
        } rounded-xl py-4 px-10 lg:px-16 text-sm lg:text-lg font-bold w-full md:w-auto`}
      >
        {routes.map((r, index) => (
          <Link
            key={index}
            className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in-out navbar-links'
            to={r.route}
          >
            {r.name}
          </Link>
        ))}
      </Flex>
    </React.Fragment>
  );
};

export default NavBar;
