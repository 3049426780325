import { toast } from 'sonner';
import { useState } from 'react';
import { Flex, Grid, Spinner } from '@radix-ui/themes';

import Input from '../Input';
import Button from '../Button';
import { loginFormDetails } from '../../data/registerForm';
import { login } from '../../helper/database_functions';
import { setEncryptedItem } from '../../helper/helper_functions';

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage('');

    const formData = new FormData(e.target);
    var data = Object.fromEntries(formData.entries());

    try {
      const instituteDetails = await login(data.email, data.password);
      if (!instituteDetails)
        throw new Error('Invalid email or password. Please try again.');

      localStorage.clear();
      setEncryptedItem('institute', instituteDetails);
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(error.message);
      setErrorMessage('Registration failed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='w-full'>
      <Flex
        width='100%'
        align='center'
        justify='center'
        direction='column'
        gap={{ initial: '4', md: '6' }}
      >
        <Grid
          width='100%'
          align='center'
          gap={{ initial: '4', md: '6' }}
          columns={{ initial: '1', sm: '2' }}
        >
          {loginFormDetails.map((field, index) => (
            <Input
              key={index}
              name={field.name}
              type={field.type}
              placeholder={field.label}
              required={field.required === false ? false : true}
            />
          ))}
        </Grid>

        <p className='text-redColor text-sm md:text-md font-semibold'>
          {errorMessage}
        </p>

        <Button
          type='submit'
          title={loading ? <Spinner size='3' /> : 'SUBMIT'}
          className='px-8 md:px-16 w-full md:w-fit bg-[#6C3483]'
        />
      </Flex>
    </form>
  );
};

export default LoginForm;
