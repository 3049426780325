import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Flex, Grid, Tabs } from '@radix-ui/themes';

import {
  schoolBoys,
  schoolGirls,
  universityBoys,
  universityGirls,
} from '../../data/sports';
import Button from '../Button';

const InstituteRegistration = ({ institute }) => {
  const sportsBoys =
    institute?.institutionType === 'university' ? universityBoys : schoolBoys;
  const sportsGirls =
    institute?.institutionType === 'university' ? universityGirls : schoolGirls;

  return (
    <Flex direction='column' align='center' gap={{ initial: '6', md: '8' }}>
      <h1 className='text-2xl md:text-3xl font-bold text-center'>
        {institute?.institute}
      </h1>

      {/* Temporarily closing registrations for unis */}
      {institute?.email !== 'cityuni@bsf.com' &&
        institute?.email !== 'londonamerican@bsf.com' &&
        institute?.institutionType === 'university' && (
          <p className='text-redColor text-lg md:text-xl text-center text-pretty'>
            Registeration have been closed except for lawn tennis. You can
            update your existing teams if you wish to.
          </p>
        )}
      <Tabs.Root
        defaultValue='boys'
        className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'
      >
        <Tabs.List className='text-lg md:text-xl font-bold'>
          <Tabs.Trigger value='boys'>BOYS</Tabs.Trigger>
          <Tabs.Trigger value='girls'>GIRLS</Tabs.Trigger>
        </Tabs.List>

        {[
          { name: 'boys', data: sportsBoys },
          { name: 'girls', data: sportsGirls },
        ].map((tab, index) => (
          <Tabs.Content value={tab.name} className='w-full h-full' key={index}>
            <Grid
              width='100%'
              align='center'
              gap={{ initial: '4', md: '6' }}
              columns={{ initial: '1', sm: '2', md: '3' }}
            >
              {tab.data.map((sport, index) => {
                const routeExists =
                  sport?.route && institute?.sports?.[sport.route];

                {
                  /* Temporarily closing registrations for unis */
                }
                if (
                  institute?.institutionType === 'school' ||
                  institute?.email === 'cityuni@bsf.com' ||
                  institute?.email === 'londonamerican@bsf.com' ||
                  sport?.route === 'lawn-tennis-boys' ||
                  sport?.route === 'lawn-tennis-girls' ||
                  routeExists
                )
                  return (
                    <Link to={`/register/${sport.route}`} key={index}>
                      <Flex
                        direction='row'
                        align='center'
                        justify='between'
                        className={`p-5 bg-grayColor rounded-xl ${
                          routeExists ? 'bg-greenColor' : ''
                        }`}
                      >
                        <p className='font-semibold'>
                          {sport?.name || 'Unnamed Sport'}
                        </p>

                        {routeExists && <Check />}
                      </Flex>
                    </Link>
                  );
              })}
            </Grid>
          </Tabs.Content>
        ))}
      </Tabs.Root>

      <Button
        title='Logout'
        className='px-8 md:px-16 w-full md:w-fit bg-redColor'
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      />
    </Flex>
  );
};

export default InstituteRegistration;
